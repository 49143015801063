<template>
    <div id="AccuracyDisclaimer" class="container-fluid">
        <h4>Our Commitment to Accuracy:</h4>
        <p>Electric Driver strives for accuracy in our calculations, algorithms and the underlying data, so please
            <a href="mailto:info@electricdriver.co?subject=Electric%20Driver%20Accuracy%20Concern">contact us</a>
            if you believe our information is in error.
        </p>
        <p>
            Electric Driver is not underwritten by any automotive manufacturer, so we attempt to introduce as little
            bias as possible into the information and statistical analysis we provide.
            Please feel free to
            <a href="mailto:info@electricdriver.co?subject=Electric%20Driver%20Suggestion">contact us</a>
            and suggest improvements.
        </p>
        <p>
            Electric Driver draws data from a variety of sources including US governmental publications as well as
            published manufacturer specifications.
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#AccuracyDisclaimer {
    color: #333333;

    h4 {
        font-size: 1rem;
    }

    p {
        font-size: 0.75rem;
    }
}
</style>